import { StyledResetButton } from '$shared/components';
import styled from '@emotion/styled';
import { readableColor } from 'color2k';
import { ifProp } from 'styled-tools';
import { Line } from '~/shared/components/Line';
import { Visibility } from '~/shared/utils/jsx';
import { breakpoints } from '~/theme';
import { getDuration, getEasing } from '~/theme/themes/animations/baseAnimations';
import { AvatarButton } from '../N21MainMenu/components';
import { StyledMetaButton } from '../N21MainMenu/styled';

type StyledCrossLineProps = {
    cross?: boolean;
};

export const StyledIconBadge = styled.span<{ backgroundColor?: string }>(
    ({ theme, backgroundColor = theme.colors.light50 }) => ({
        marginLeft: '0.5em',
        fontSize: theme.fontSizes['sm'],
        width: 22,
        height: 24,
        backgroundColor: backgroundColor,
        color: readableColor(backgroundColor),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
    })
);

export const StyledMainNavigation = styled.header(({ theme }) => ({
    top: 0,
    position: theme.traits.headerIsSticky ? 'sticky' : 'relative',
    backgroundColor: theme.colors.light,
    zIndex: theme.zIndices['header'],
    display: 'flex',
    justifyContent: 'center',
    padding: `0 ${theme.gridConfig.gutter}`,
    borderBottom: theme.borders.border,

    [breakpoints.md]: {
        borderTop: theme.borders.border,
    },
}));

export const StyledSection = styled.div({
    display: 'grid',
    height: '100%',
    gridAutoFlow: 'column',
});

export const StyledIcons = styled(StyledSection)({
    display: 'flex',
    justifyContent: 'flex-end',
    flexShrink: 0,
});

export const StyledIcon = styled(StyledResetButton)(({ theme }) => ({
    height: '100%',
    padding: theme.spaces[2],
    outlineOffset: -1,
    fontSize: theme.fontSizes['2xl'],

    '&:active': {
        backgroundColor: theme.colors.light30,
    },

    [breakpoints.md]: {
        height: 'auto',
        '&:hover': {
            backgroundColor: theme.colors.light30,
        },
    },
}));

export const StyledLeftArrow = styled(StyledIcon)(({ theme }) => ({
    marginLeft: `-${theme.spaces[2]}`,
}));

export const StyledContent = styled.div(() => ({
    display: 'flex',
    width: '100%',

    [breakpoints.md]: {
        padding: 0,
    },
}));

export const StyledDesktopNav = styled(StyledSection)({
    display: 'none',

    [breakpoints.md]: {
        display: 'grid',
        gridAutoFlow: 'column',
        justifyItems: 'start',
        flex: 1,
    },
});

export const StyledLogoWrapperMobile = styled(Visibility.HiddenFrom)();

export const StyledLogoWrapperDesktop = styled(Visibility.VisibleFrom)({
    // flex: '1'
});

export const StyledLogoContainer = styled.div(({ theme }) => ({
    height: theme.sizes.N20headerHeight,
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'center',
    marginRight: theme.spaces[3],
    
    [breakpoints.md]: {
        justifyContent: 'center',
        maxWidth: theme.sizes.logoHeaderWidth,
        height: theme.sizes.N20headerHeightDesktop,
    },
}));

export const StyledMobileMenuHeader = styled.h1(({ theme }) => ({
    fontSize: theme.fontSizes.md,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

export const StyledBasketButton = styled(StyledIcon)<{ active?: boolean }>(
    ({ theme }) => ({
        position: 'relative',
        height: '100%',

        [breakpoints.md]: {
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            padding: `0 ${theme.spaces[5]}`,
            borderRight: theme.borders.border,
        },
    }),
    ifProp('active', ({ theme }) => ({
        backgroundColor: theme.colors.light30,
    }))
);

export const StyledMobileMenuButton = styled(StyledIcon)(({ theme }) => ({
    marginRight: `calc(${theme.spaces[2]} * -1)`,
    [breakpoints.md]: {
        display: 'none',
    },
}));

export const StyledLineContainer = styled.div(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'column',
    height: theme.spaces['20px'],
    width: theme.spaces['20px'],
}));

export const StyledCrossLine = styled(Line)<StyledCrossLineProps>(
    {
        transitionProperty: 'transform',
        transitionDuration: getDuration('moderate01'),
        transitionTimingFunction: getEasing('exit', 'expressive'),
    },
    ifProp('cross', {
        transform: 'rotate(45deg) translate3d(4px, 4px, 0px)',
        transitionTimingFunction: getEasing('entrance', 'expressive'),

        '&:last-child': {
            transform: 'rotate(-45deg) translate3d(3px, -3px, 0px)',
        },
    })
);

export const StyledMobileMenuContainer = styled.div({
    display: 'flex',
    alignItems: 'flex-end',
});

export const StyledMobileSearch = styled(StyledMetaButton)(({ theme }) => ({
    flex: 1,
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    borderLeft: `1px solid ${theme.colors.dark}`,

    [breakpoints.md]: {
        display: 'none',
    },
}));




export const StyledDesktopSearchBar = styled.div({
    display: 'none',

    [breakpoints.md]: {
        display: 'flex',
        flex: '1',
        justifyContent: 'flex-end'
    },
});


export const StyledM20AvatarButton = styled(AvatarButton)(({ theme }) => ({
    padding: theme.spaces[3],
    [breakpoints.md]: {
        paddingInline: theme.spaces[5],
        '&:hover': {
            backgroundColor: theme.colors.light30,
        },
    },
}));
